import React from "react";
import claimCompensation
  from "../images/claim-compensation-for-your-flight.png";
import Button from "./Button";
import clsx from "clsx";

const FooterJumbotron = ({noSkew, children}) => {
  return <div className={clsx("fifth-section text-center lg:text-left relative sm:pb-30 pt-50 lg:pt-120",
  {"fifth-section-no-skew": noSkew}
  )}>
    <div className="container mx-auto w-full mdd:max-w-none relative">
      <div className="mx-20 sm:mx-auto flex sm:flex-col flex-row items-center lg:justify-between justify-center">
        <div className="lg:block hidden sm:w-auto max-w-425">
          <img
            src={claimCompensation}
            alt="Green Claim Logo"
            width={429}
            height={439}
          />
        </div>
        <div
          className="mt-29 lg:mt-49 w-full flex-shrink-0 flex align-center
              lg:items-start items-center flex-col self-start max-w-675"
        >
          {children || <><h2 className="pb-25">Claim compensation for your flight delay, cancellation or overbooking!</h2>
          <Button
            theme="primary"
            showIcon={false}
            width="auto"
            tag="a"
            href="https://claim.flight-delayed.com/"
            target="_blank"
          >
            Check my flight
          </Button></>}
        </div>
      </div>
    </div>
  </div>
}

export default FooterJumbotron;