import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ArticleSection from "../components/ArticleSection"
import ArticlePage from "../components/ArticlePage"
import FooterJumbotron from "../components/FooterJumbotron"

const HowItWorks = () => (
  <Layout>
    <SEO title="Our services" />
    <ArticlePage title={"Our services"}>
      <ArticleSection className="text-center">
        <h2>Applicability of Regulation EC 261/2004</h2>
        <p>
          The main piece of legislation that establishes passenger rights in the
          EU and UK is Regulation EC 261/2004. The Civil Aviation Authority has
          stated that airlines must continue to comply with the regulation after
          Brexit, so your rights will be covered for the foreseeable future when
          flying to and from the United Kingdom.
        </p>
        <p>
          Here is a simplified version of how the regulation determines which
          flights are covered. In other words, when your passenger rights are
          protected:
        </p>
        <ul>
          <li>- All flights within the UK and the EU</li>
          <li>- All Flights departing from the UK and the EU</li>
          <li>
            - Flights arriving in the UK and EU from outside the UK and EU that
            are operated by a UK or EU airline
          </li>
        </ul>
        <p>
          All passengers fall under the scope of the regulation unless the
          passenger is travelling on a free ticket or when flying on a
          discounted fare not available to the general public. Passengers under
          the age of two years normally won’t be covered as they tend to fly
          with price-reduced tickets or under a special fare.
        </p>
        <h2>Your right to be compensated for flight disruptions</h2>
        <p>
          The law sets out two scenarios in which the airline is obligated to
          compensate passengers for the inconveniences caused by a flight
          disruption. The first option is to receive a refund when your flight
          has been cancelled and you have not been offered an acceptable
          alternative flight. This is something that is occurring frequently
          during the coronavirus pandemic. The second option examines the
          admissibility for passengers to receive financial compensation for the
          time lost due to travel disruptions. This covers flight delays, flight
          cancellations and overbooking. Monetary compensation is only due if
          the flight disruption in question was not caused by an extraordinary
          circumstance. Bellow, you’ll find a more in-depth explanation of both
          scenarios.
        </p>
        <h2>Flight refund</h2>
        <p>
          If your flight has been cancelled by the airline, or if your schedule
          has changed drastically, you are entitled to the full refund of your
          fare if the airline fails to provide you with a suitable alternative.
          Your new (alternative) flight should not depart 2+ hours prior and
          arrive 5+hours later than your original schedule. Airlines, in almost
          all cases, will not comply with the criteria so almost all passengers
          whose flights have been cancelled will be entitled to the full refund
          of their tickets.
        </p>
        <p>
          If you have been offered a replacement flight that fulfils the
          requirements, there is still the possibility that you may be entitled
          to a refund. Submit your claim and our team will review how your
          flight schedule changed and when you were informed of the
          cancellation. We have a dedicated page on when you can claim a refund
          if your flight is cancelled where you can learn all about your rights.
          If you choose to submit your claim with Flight-Delayed, we’ll make
          sure to properly assess all the circumstances affecting your flight
          and secure every form of compensation that may be entitled to.
        </p>
        <h2>Business services</h2>
        <p>
          Online Travel Agencies (OTA), travel organisations and travel
          insurrance companies all are very important links in the travel
          industry. But what if things go wrong and your customers need
          assistance? We offer white-label solutions with complete customer
          contact solutions. We service your customers, handle all communication
          and administrative handling and when due, get your customers what
          they're entitled to.
        </p>
        <p>
          Do your employees frequently travel or are you an OTA, travel
          organisation or insurrance company and are you looking for customer
          contact solutions, assistance with 261 compensation claims, refund
          claims, airport tax claims or any other travel related issue, do not
          hesitate to contact us to explore the possibilities at
          info@yource.com.
        </p>
        <h2>Reduce your cost per claim (insurance companies)</h2>
        <p>
          If passengers do not use their flight ticket due to no-show or
          cancellation, they are entitled to a refund of airport taxes. Airlines
          and travel agents usually do not contact their customers about this
          because they hold the airport taxes themselves. All airline tickets
          are eligible for a refund of airport taxes, including so-called
          non-refundable tickets or low-cost tickets from, for example, easyJet.
        </p>
        <p>
          We help insurance companies to lower their claim costs. This service
          is applicable to all airline tickets, regardless of airline, ticket
          type or package deal. For more information or partnership options you
          can send an email to{" "}
          <a href="mailto:info@re-fund.com">info@re-fund.com</a>.
        </p>
      </ArticleSection>
      <FooterJumbotron noSkew />
    </ArticlePage>
  </Layout>
)

export default HowItWorks
