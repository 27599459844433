import React from "react";
import childImage from "../images/get-compensated-flight-delayed.png";

const ArticlePage = ({title, children}) => {
  
  return <><section className="header-section container w-full mx-auto mdd:max-w-none md:h-220">
    <div className="sm:mx-auto px-60 flex flex-col md:flex-row items-center align-center lg:justify-between md:h-220">
      <div
        className="w-full sm:w-auto flex-shrink-0 flex align-center
            lg:items-start items-center flex-col lg:max-w-header-1 lg:text-left text-center"
      >
        <h1 className="py-50">
          {title}
        </h1>
      </div>
      <div className="hidden md:block self-end">
        <img
          src={childImage}
          alt="Child looking through telescope"
          width={261}
          height={253}
        />
      </div>
    </div>
  </section>{children}
    </>
}

export default ArticlePage;