import React from "react";
import clsx from "clsx";

const ArticleSection = ({children, className}) => {
  
  return <section className="bg-white">
    <article className={clsx("article container mx-auto p-80 pb-20", className)}>
      {children}
    </article>
  </section>
}

export default ArticleSection;