import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Styles
const styles = {
  primary:
    'bg-orange-400 border-orange-400 hover:bg-orange-500 hover:border-orange-500 text-white',
  secondary:
    'text-green-400 border-green-400 hover:bg-green-200 hover:border-green-200',
  'secondary fill':
    'text-white border-green-400 bg-green-400 hover:bg-green-200 hover:border-green-200',
  info: 'text-gray-300 border-gray-300 hover:bg-gray-300',
  disabled: 'opacity-50 cursor-not-allowed pointer-events-none',
  small: 'text-root h-40 px-25 min-w-0',
  medium: 'h-60 px-49 min-w-button text-lg',
  large: 'h-50 px-25 min-w-button text-lg',
  full: 'w-full',
  auto: 'w-auto',
};

const Button = ({
  children,
  className,
  disabled,
  theme,
  size,
  showIcon,
  width,
  tag: Tag,
  ...props
}) => {
  return (
    <Tag
      className={clsx(
        'button sm:w-auto no-underline',
        'hover:text-white text-center items-center inline-flex justify-center border font-pt-sans font-bold',
        'rounded-full',
        {
          [styles[theme]]: theme,
          [styles[size]]: size,
          [styles[width]]: width,
          [styles.disabled]: disabled,
        },
        className
      )}
      {...props}
    >
      {children}
    </Tag>
  );
};

Button.defaultProps = {
  disabled: false,
  size: 'medium',
  showIcon: true,
  tag: 'button',
  width: 'full',
};

Button.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  showIcon: PropTypes.bool,
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  tag: PropTypes.oneOf(['a', 'button']),
  theme: PropTypes.string,
  width: PropTypes.oneOf(['full', 'auto']),
};

export default Button;
